.sm-border{
  display: block;
  height: 2px;
  width: 70px;
  margin-bottom: 15px;
  bottom: -5px;
  position: relative;
  left: 47%;
  background-color: rgb(4, 181, 187);
}


.portfolio{
color:#fff;

  &__desc{
    padding: 10px;

    h5{
      padding-bottom: 20px;
      color:#fff;
      span{
        color: rgb(4, 181, 187);
      }
    }
    p{
      color:#fff;
    }
  }
  &__links{
    
    button{
      border: 1px solid rgb(4, 181, 187);
      padding: 5px 10px ;
      border-radius:10px ;
      background-color: rgb(4, 181, 187);
      color: #fff;
      animation: buttonS  4s infinite ;
    }
    
   }
  

  &__heading{
      margin-bottom: 100px;
     h2{
       text-align: center;
       text-transform: uppercase;
       margin-top: 120px;
       font-weight: 700;
       color: #fff;

 
       span{
         color: rgb(4, 181, 187);
       }
     }
   }

   &__lists{
ul{
  display: flex;
  justify-content: center;
  li{
  list-style-type: none;
  margin-left: 10px;
    a{
      text-decoration: none;
      color: rgb(4, 181, 187);
      padding: 10px 25px;
      border: 1px solid rgb(4, 181, 187);
      border-radius: 20px;
      transition: .2s ease-in;

      &:hover{
        background-color: rgb(4, 181, 187);
        color: #fff;

      }
    }
  }
}
   }

   &__appProduct{
    position: relative;
    margin-top: 100px;
  

    img{
      max-width: 100%;
      height: auto;
      box-shadow: 2px 4px 3px rgb(4, 181, 187);
    }
   }

  
  }



  a{
    text-decoration: none;
    color: rgb(31,34,53);
    font-weight: 400;
    font-size: 14px;

    &:hover{
      color:  rgb(31,34,53);
     
        }
  }

  @keyframes buttonS {
    0%{
      transform: rotate(0);
    }

    25%{
      transform: rotate(45deg);
    }
    50%{
      transform: rotate(180deg);
    }
    75%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0);
    }
  }






  // media query

   @media only screen and (max-width: 767px) {
       .portfolio{

          
        &__desc{
          margin-top: 20px;
     
          
          
         }

           &__main{
            text-align: center;
          
           }

         &__lists ul {
           display: flex;
           flex-direction: column;
           text-align: center;
           li{
             padding-top: 30px;
           }
         }
        }


    .boxes-planshet{
    align-items: center;
    text-align: center;
  }
 
    }

  