* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:  'Poppins', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

@import "_inc/header";
@import "_inc/footer";
@import "index.scss";
@import "services.scss";
@import "portfolio.scss";
@import "mails.scss"



