.services{

  &__heading{
     margin-bottom: 100px;
    h2{
      text-align: center;
      text-transform: uppercase;
      margin-top: 120px;
      font-weight: 700;
      color: #fff;

      span{
        color: rgb(4, 181, 187);
      }
    }

  }
  &__head{
    h4{
      color: rgb(4, 181, 187);
    }
  }

  &__inners{
   
    p{
      color:#fff;
    }
  }

  &__technologies{
    padding-top: 120px;
    ul{

      display: flex;
      justify-content: center;
      padding-left: 0;
      li{
        padding: 5px;
        animation: hideIn 6s infinite ;
        list-style-type: none;
        img{
          width: 90px;
        }
      }
    }
  }

  
    &__imgs{
      i{
        color: rgb(4, 181, 187);
        font-size: 46px;
        margin-bottom: 15px;
      }
    }
}

.materialize img{
  width: 200px !important;
}

.sm-border{
    display: block;
    height: 2px;
    width: 70px;
    margin-bottom: 15px;
    bottom: -5px;
    position: relative;
    left: 47%;
    background-color: rgb(4, 181, 187);
}



@keyframes hideIn {
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.9;
    transform: rotate(45deg);
  }
  100%{
    opacity: 1;
    transform: scale(1.2);
  }
}


// media query

@media only screen and (max-width: 991px) {
.services{
  &__technologies{
    ul{
      flex-direction: column;
      align-items: center;
    }
  }
}
  
  }
   
       