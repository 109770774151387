header{
    height: 90px;
    position: sticky;
    top: 0;
    backdrop-filter: blur(5px);
    z-index: 999;
    border-bottom: 1px solid rgb(4, 181, 187);
}

body{
    background-color: rgb(0, 19, 19);
}
.menu{
    display: flex;
    position: relative;

    &__bar{
        display: none;
     ul{
        li{
            list-style-type: none;
             i{
             color: rgb(4, 181, 187) !important;
             font-size: 22px;
             position: absolute;
             top: -1px;
             left: 25px;
            }
            };
           
        }
    }


&__logo {

    a{
        text-decoration: none;
        
    }
    p{
        color: rgb(4, 181, 187);
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;

    }
}
    &__main{
        position: relative;
        padding-top: 20px;
    }

&__nav{
  ul{
    display: flex;
    justify-content: center;
    
    li{
        list-style-type: none;
        a{
            margin-left: 50px;
            text-decoration: none;
            font-size: 18px;
            font-weight: 500;
            color: rgb(4, 181, 187);

        }
    }
  }
}
}



.mobile__hidden{
    display: none;
}
.scrolss__hidden{
    overflow: hidden;
}
// mobile menu
.mobile{
    position: relative;
    &__menu{
        background-color:rgb(4, 181, 187);
        box-shadow: rgb(4, 181, 187);
        width:35%;
        height: 100vh;
        position: absolute;
        z-index: 999;
        top: 0;
        bottom: 0;
        right: 0;
        transition: all 0.5s ease-in-out;
         animation: anim 1s ease;
        
    }
    &__sub{
       text-align: center;
       margin-top: 110px;
        li{
            list-style-type: none;
            padding-top: 25px;
            a{
                text-decoration: none;
                font-size: 32px;
                color: rgb(4, 181, 187);

            }
        }
    }

    &__close{
      
            position: absolute;
            top: 27px;
            right: 30px;
            i{
                color: rgb(4, 181, 187);

                font-size: 25px;
            }
      
    }
}
 @keyframes anim{
   0%{ transform: translateX(100%) ;}
   100%{ transform: translateX(0%)}
 }

// mobile menu

// header media query

@media only screen and (max-width: 991px) {

.menu {
  &__nav{

    display: none;
  }
       
  &__bar{
    position: absolute;
    right: 45px;
    top: 25px;
    display: block;
    }
   
        
}




}


@media only screen and (max-width:620px){
    .mobile{
        position: relative;
        &__menu{
            background-color: rgb(4, 181, 187);
            box-shadow: rgb(4, 181, 187);
            width:100%;
            height: 100vh;
            position: absolute;
            z-index: 999;
            top: 0;
            bottom: 0;
            right:0;
            transition: all 0.5s ease-in-out;
             animation: anim 1s ease;
            
        }
        &__sub{
           text-align: center;
           margin-top: 110px;
            li{
                list-style-type: none;
                padding-top: 25px;
                a{
                    text-decoration: none;
                    font-size: 32px;
                    color: rgb(4, 181, 187);
                }
            }
        }
    }    
}
    





