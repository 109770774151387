

h1{
  margin-bottom: 100px;

  span{
    color: #ff4a57;
  }
}

.freeBonus{
&__texts{
  max-width: 320px;
  margin-top: 15px;
}

img{
  width: 100%;
}

}






