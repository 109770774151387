.my{
      margin: 0 auto;
      color:#fff;
    &__welcome{
      h3{
        color: rgb(4, 181, 187);
        text-transform: uppercase;
        font-weight: 700;
        padding-top: 20px;
      }

      p{
        padding-top: 20px;
        font-weight: 600;
        font-size: 42px;
      }
      span{
        padding-top: 20px;
        display: block;
        font-size: 18px;
        font-weight: 400;
      }
    }

    


    &__about{
   
        img{
          filter: opacity(50%);
          width: 300px; 
          box-shadow: 0px 4px 0px 0px rgb(4, 181, 187);
             }


        
    h4{
        margin-top: 61px;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 30px;
        padding-bottom: 20px;
    }
    }
.texts{
    font-weight: 400;
    font-size: 16px;
}
    // &__desc{

 
      
    // }

    &__info{
      margin-top: 40px;
        p{
            font-weight: 400;
            font-size: 20px;
            color:rgb(4, 181, 187);
            a{
              text-decoration: none;
              color: #fff;
              font-weight: 400;
            }

            span{
                font-weight: 400;
                font-size: 18px;
                color: #fff;
            }
        }
    }

&__resume{
    margin-top: 55px;
    a{
        color: #fff;
        text-decoration: none;
        background-color: rgb(4, 181, 187);
        padding: 15px 25px;
        text-transform: capitalize;
        border-radius:14px ;
        border: 1px solid rgb(4, 181, 187);
        transition: .2s ease;
        font-size: 18px;
        font-weight: 500;
        animation: slideIn 3s infinite ;
        

    }
}


    &__social{
        margin-top: 20px;
        ul{
            display: flex;
            padding-left: 0;
            justify-content: flex-start;
            li{
                list-style-type: none;
                font-size: 22px;
                a{
                    color: rgb(4, 181, 187);
                    transition: .2s ease-in;
                    padding: 10px;
                    border-radius: 20px;
                  

                    &:hover{
                        background-color: rgb(4, 181, 187);
                        color: #fff;
                    }
                }
            }
        }
    }
}

@keyframes slideIn {
  0%{
    background-color: #fff;
    color: rgb(4, 181, 187);
  }

  25%{
    background-color: rgb(4, 181, 187);
    color:#fff ;
  }
  50%{
    background-color: #fff;
    color:rgb(4, 181, 187);
  }
  75%{
    background-color:  rgb(4, 181, 187);
    color:#fff;
  }
  100%{
    color:rgb(4, 181, 187);
    background-color: #fff  ;
 
  }
}

// media query indexs


@media only screen and (max-width: 767px) {

 .my{
  &__images{
   img{
    margin-bottom: 20px;
    margin-left: 0;
   }
  }

  &__about{
    img{
      display: none;
    }
  }

  &__abouts{
    display: block !important;
    margin-top: 50px;
    img{
     width: 290px;
    }
  }
 }
  
  
      
  }
  @media only screen and (max-width: 320px) {

    .my{
      &__resume{
        text-align: center;
      }
    }
     
     
         
     }

     @media only screen and (max-width: 400px) {

      .loading__animation img{
        max-width: 100%;
      }
      
       
       
           
       }


 


// animation
.loading__animation {
  display: block; /* Измените на "none" если хотите скрыть анимацию при загрузке */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* Полупрозрачный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Выше других элементов */
}


/* Стили для содержимого страницы */
.page__content {
  display: none; /* Скрыть содержимое страницы изначально */
}
// animation



// scroll to top 

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  cursor: pointer;
}

.scroll-to-top img {
  width: 60px;
  height: 60px;
}
